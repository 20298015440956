header nav {
    float: right;
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
        float: none;    
    }
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        @media (max-width: 768px) {
            padding-top: 20px; 
            margin-bottom: 20px;
        }

        li {
            padding: 0;
            display: inline-block;

            a, a:link, a:visited, a:hover, a:active {
                line-height: 37px;
                padding: 0 20px;
                font-size: 16px;
                color: #000;
                font-weight: 600;

                @media (max-width: 768px) {
                    line-height: 36px;
                    display: block;
                }
            }
            
            a:last-child {
                padding-right: 0;
            }

            a:hover,
            &.current-menu-item a {
                color: #fcc81a;
                text-decoration: none;
            }

            @media (max-width: 768px) {
                display: block;
            }
        }
    }
}


footer nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
        padding: 0;
        display: inline-block;
        
        a, a:link, a:visited, a:hover, a:active {
            color: #fff;
            padding-right: 10px;
            font-size: 12px;
            line-height: 38px;
        }
        
        a:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
    
}