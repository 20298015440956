p {
    margin: 0 0 20px;
}

body {
    color: #000;
    font-family: OpenSans, arial, sans-serif;
    font-size: 15px;
    line-height: 24px;
}

.alignright {
    float: right;
    margin-left: 40px;
}

.alignleft {
    float: left;
    margin-right: 40px;
}

h4 {
    margin-top: 20px;
    font-weight: bold;
}

.top-header {    
    .logo {
        margin-top: 25px;
        @media (max-width: 768px) {
            text-align: center;
        }
    }
    
    @media (max-width: 992px) {
        nav {
            clear: both;
        }
    }
}

.slideshow {
    width: 100%;
    
    overflow: hidden;
    position: relative;
    
    .bubble {
        position: absolute;
        background: #0d7236;
        background: rgba(13,114,54, .6);
        border-radius: 50%;
        height: 350px;
        width: 350px;
        
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 50px;
        padding-top: 65px;
        top: 50px;
        z-index: 9999;
        
        .title {
            font-weight: 900;
        }
        
        .btn {
            color: #0d7236;
        }
    }
}

.call-now {
    margin-top: 25px;
    text-align: right;
    
    color: #000;
    font-weight: 600;
    
    i.glyphicon {
        float: left;
        margin-top: 5px;
        margin-right: 5px;
        
        color: #afafaf;
        font-size: 38px;
    }
    
    span {
        color: #0d7236;
        font-size: 16px;
        font-weight: bold;
    }
}

.bottom-header {
    background: #fcc81a;
    padding: 20px 0;
    margin-bottom: 40px;
    
    h1 {
        color: #2b2b27;
        font-size: 32px;
        font-weight: 900;
        
        margin: 0;
        margin-bottom: 10px;
    }
    
    h2 {
        color: #2b2b27;
        font-size: 24px;
        font-weight: normal;
        
        margin: 0;
    }
}

.content {
    h3 {
        font-size: 24px;
        font-weight: bold;
    }
    
    ul {
        margin: 20px 0;
        padding: 0;
        list-style: none;
        
        li {
            background: url(../../images/bullet.png) no-repeat 0 4px;
            padding-left: 30px;
            margin: 10px 0;
        }
    }
    
    img {
        height: auto;
        max-width: 100%;
    }
}

.usps {
    background: #f6f7f7;
    padding: 20px;
    margin-bottom: 40px;
    padding-bottom: 0;
    
    .usp {
        box-sizing: border-box;
        float: left;
        width: 50%; 
        
        padding: 0 0 0 30px;
        margin-bottom: 20px;
        
        position: relative;
        
        p {
            margin: 0;
        }
        
        @media (max-width: 768px) {
            width: 100%;   
        }
        
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 4px;
            background: #0d7236;
            
            height: 15px;
            width: 15px;
            border-radius: 50%;
            
            color: #fff;
            font-size: 8px;
            line-height: 16px;
            text-align: center;
        }
        
        &.one:before {
            content: "1";
        }
        
        &.two:before {
            content: "2";
        }
        
        &.three:before {
            content: "3";
        }
        
        &.four:before {
            content: "4";
        }
        
        &.five:before {
            content: "5";
        }
        
        &.six:before {
            content: "6";
        }
    }
}

.actie {
    background: #0d7236;
    height: 230px;
    
    @media (max-width: 992px) {
        height: auto;
    }
    
    .bubble {
        background: url(../../images/onkruid-verwijderen-button.png);
        height: 261px;
        width: 261px;
        margin-top: -15px;
        margin-bottom: -15px;
        margin-left: 30px;
        
        @media (max-width: 992px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    .title {
        margin-top: 40px;
        
        font-size: 36px;
        font-style: normal;
        font-weight: 900;
        line-height: 36px;
    }
    
    p {
        color: #fff;
        font-style: italic;
        text-align: center;
    }
    
    .btn {
        color: #000;
        font-style: normal;
        font-weight: bold;
    }
}

.call-to-action {
    background: #f4f4f4;
    border-left: 5px solid #fcc81a;
    margin: 40px 0;
    padding: 30px 20px;
    
    .title {
        font-size: 30px;
        font-weight: 900;
        margin-bottom: 20px;
    }
    
    .btn {
        background: #fcc81a;
        border-color: #fcc81a;
        padding: 10px 30px;
        font-weight: bold;
        margin-top: 17px;
        
        @media (max-width: 992px) {
            width: 100%;
        }
    }
}

.sidebar {
    @media (max-width: 992px) {
        margin-bottom: 40px;
    }
}

.sidebar #text-2 {
    background: #fff;
    border: 2px solid #ebebeb;
    
    padding: 35px 40px;
    
    border-radius: 18px;  
    
    @media (max-width: 992px) {
        margin-top: 20px;;   
    }
    
    p {
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 30px;
    }
    
    p.title {
        color: #0d7236;
        font-size: 28px;
        line-height: 30px;
        font-weight: 900;
        margin-bottom: 10px;
    }
    
    .btn {
        background: #0d7236;
        border-color: #0d7236;
        color: #fff;
        
        font-size: 22px;
        font-weight: 900;
        
        width: 100%;
    }
}

.home .btn-home {
    background: #0d7236; 
    border-color: #0d7236; 
    padding: 10px 20px; 
    font-weight: bold; 
    font-size: 16px; 
    margin: 5px 0; 
    display: inline-block;
}

.home .sidebar #text-2 {
     margin-top: -232px;
}

ul.social-media {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        display: inline-block;
        margin-right: 10px;
        
        &:last-child {
            margin-right: 0;
        }
    }
}

.widget {
    margin-bottom: 20px;
    
    .title {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    
}

footer .widget {
    margin-bottom: 0;
}

.contact-form {
    label {
        font-size: 15px;
        font-weight: normal;
    }
    
    textarea {
        height: 150px;
    }
    
    .btn {
        margin-top: 20px;
        float: right;
        background: #0d7236;
        padding: 10px 100px;
    }
}

.sign-in-form {
    .table {

        margin-bottom: 0;
        
        th {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            color: #000;
        }
        
        td {
            border: none;
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            
            label {
                font-weight: normal;
            }
        }   
    }
    
    .form-inline {
        margin-bottom: 20px;
        
        @media (max-width: 992px) {
            .form-group {
                margin-bottom: 20px;
            }
        }
    }
    
    small {
        margin-bottom: 10px;
        display: inline-block;
    }
    
    .btn.btn-primary {
        background: #0d7236;
    }
}

.page-template-full-width footer {
    margin-top: 0;
}

footer {
    background: #262727;
    margin-top: 40px;
    padding: 40px 0;
}

#wpcf7-f41-p11-o1,
#wpcf7-f49-p9-o1 {
    margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
    .wp-image-226,
    .wp-image-227,
    .wp-image-228 {
        display: block;
        float: none;
        margin-left: auto;
        margin-right: auto !important;
        margin-bottom: 20px;
        max-width: 100%;
        width: auto;
    }
}