@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-Light-webfont.eot');
    src: url('fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-Light-webfont.woff') format('woff'),
         url('fonts/OpenSans-Light-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-LightItalic-webfont.eot');
    src: url('fonts/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('fonts/OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-Regular-webfont.eot');
    src: url('.fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-Italic-webfont.eot');
    src: url('fonts/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-Italic-webfont.woff') format('woff'),
         url('fonts/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-Bold-webfont.eot');
    src: url('fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-Bold-webfont.woff') format('woff'),
         url('fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-BoldItalic-webfont.eot');
    src: url('fonts/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-Semibold-webfont.eot');
    src: url('fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-Semibold-webfont.woff') format('woff'),
         url('fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-SemiboldItalic-webfont.eot');
    src: url('fonts/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('fonts/OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic') format('svg');
    font-weight: 800;
    font-style: italic;
}



@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-ExtraBold-webfont.eot');
    src: url('fonts/OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-ExtraBold-webfont.woff') format('woff'),
         url('fonts/OpenSans-ExtraBold-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-ExtraBold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-ExtraBoldItalic-webfont.eot');
    src: url('fonts/OpenSans-ExtraBoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
         url('fonts/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype'),
         url('fonts/OpenSans-ExtraBoldItalic-webfont.svg#open_sansextrabold_italic') format('svg');
    font-weight: 900;
    font-style: italic;
}